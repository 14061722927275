import { useQuery } from "react-query";

import Blog from "../api/Blog";
import BlogCategory from "../api/BlogCategory";

export const useGetBlog = () => {
    const getBlogs = async () => {
        const response = await Blog.Get();

        if (response.data.status !== 200) {
            throw new Error("An error has occured!");
        }

        return response.data.data;
    };

    return useQuery("Blogs", () => getBlogs());
};

export const useGetBlogCategories = () => {
    const getBlogsCategories = async () => {
        const response = await BlogCategory.Get();

        if (response.data.status !== 200) {
            throw new Error("An error has occured!");
        }

        return response.data.data;
    };

    return useQuery("blog-categories", () => getBlogsCategories());
};
