import axios from "axios";
import BaseUrl from "./BaseURL";
import Cookies from "js-cookie";

export default () => {
  let instance = axios.create({
    timeout: 1000 * 60 * 10,
    // send token
    headers: {
      Authorization: Cookies.get("token") ? Cookies.get("token") : null,
      // organization: process.env.REACT_APP_ORGANIZATION_ID,
    },
    baseURL: BaseUrl.baseURL,
  });

  instance.interceptors.response.use(
    function (response) {
      if (response.data.status === 403) {
        Cookies.remove("token");
        localStorage.clear();
        window.location = "/login";
      }

      // Any status code within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return instance;
};
