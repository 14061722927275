// hooks
import React from "react";

// components
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";

import JsBarcode from "jsbarcode";
import LuwjistikLogo from "../../assets/images/luwjistik-logo.png";
import Logo from "../../assets/images/logo.png";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import moment from "moment";

const getBarcode = (data) => {
  let canvas;
  canvas = document.createElement("canvas");
  JsBarcode(canvas, data, {
    displayValue: false,
  });
  const barcode = canvas.toDataURL();
  return barcode;
};

const PackageLabel = ({ values }) => {
  return (
    <Document>
      {values.map((data) => (
        <Page key={data.id} size="A5" style={{ padding: "20px", fontSize: "8px" }}>
          {/* container */}
          <View style={{ width: "100%", height: "100%" }}>
            <View style={{ display: "flex", flexDirection: "row", gap: "24px", justifyContent: "space-between" }}>
              <Text>Invoice {data.transaction_id}</Text>
              <View style={{ display: "flex", alignItems: "center" }}>
                <Image style={{ height: "15px", width: "95px", objectFit: "contain" }} source={Logo} />
              </View>
            </View>
            <View style={{ borderBottom: "1px solid black", paddingBottom: "10px" }}>
              <Text>
                <Text style={{ letterSpacing: "0.8px" }}>Order Information</Text>
              </Text>
            </View>
            <View style={{ width: "100%", display: "flex", marginTop: "10px", gap: "10px", flexDirection: "row" }}>
              <View style={{ width: "33,3333%", display: "flex", gap: "8px" }}>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>Order Datetime:</Text>
                  <Text>{moment(data.payment_date).format("YYYY-MM-DD")}</Text>
                </View>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>Status:</Text>
                  <Text style={{ textTransform: "uppercase" }}>{TransactionStatus[data.status]}</Text>
                </View>
              </View>
              <View style={{ width: "33,3333%", display: "flex", gap: "10px" }}>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>Receiver:</Text>
                  <Text>{data.receiver_address.user_name}</Text>
                  <View>
                    {/* address */}
                    <Text style={{ letterSpacing: "0.6px", lineHeight: "1.5px" }}>{data.receiver_address.address}</Text>
                    {/* phone number */}
                    <Text style={{ letterSpacing: "0.6px", lineHeight: "1.5px" }}>
                      {data.receiver_address.district}
                      {data.receiver_address.city}
                      {data.receiver_address.province} {data.receiver_address.country_data.name}, {data.receiver_address.postal_code}
                    </Text>
                    <Text>Phone. {data.receiver_address.phone_number}</Text>
                  </View>
                </View>
              </View>
              <View style={{ width: "33,3333%", display: "flex", gap: "10px" }}>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>Sender:</Text>
                  <Text>{data.organization.name}</Text>
                  <View>
                    {/* address */}
                    <Text style={{ letterSpacing: "0.6px", lineHeight: "1.5px" }}>{data.organization.address}</Text>
                    {/* phone number */}
                    <Text style={{ letterSpacing: "0.6px", lineHeight: "1.5px" }}>
                      {data.organization.district}
                      {data.organization.city}
                      {data.organization.province} {data.organization.country.name}, {data.organization.postal_code}
                    </Text>
                    <Text>Phone. {data.organization.phone_number}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                marginTop: "10px",
                gap: "10px",
                flexDirection: "row",
                borderTop: "1px solid black",
                paddingTop: "10px",
              }}
            >
              <View style={{ width: "33,3333%", display: "flex", gap: "8px" }}>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>Delivery Method:</Text>
                  <Text>{data.courier.name}</Text>
                  <Image style={{ height: "20px", width: "80px" }} source={LuwjistikLogo} />
                </View>
              </View>
              <View style={{ width: "33,3333%", display: "flex", gap: "10px" }}>
                <View style={{ gap: "4px", display: "flex" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>AWB Number:</Text>
                  <Text>{data.resi || "-"}</Text>
                  {data.resi ? <Image style={{ height: "20px", width: "100px" }} source={getBarcode(data.resi || "-")} /> : null}
                </View>
              </View>
              <View style={{ width: "33,3333%" }}></View>
            </View>
            <View style={{ borderBottom: "1px solid black", paddingBottom: "10px", marginTop: "40px" }}>
              <Text>
                <Text style={{ letterSpacing: "0.8px" }}>Product Information</Text>
              </Text>
            </View>

            <View style={{ marginTop: "20px" }}>
              {/* table */}
              <View style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                <Text style={{ width: "37%" }}>Product Name</Text>
                <Text style={{ width: "19%" }}>Odoo</Text>
                <Text style={{ width: "19%" }}>Barcode</Text>
                <Text style={{ width: "10%" }}>Quantity</Text>
              </View>
              <View style={{ marginTop: "15px", display: "flex", gap: "10px" }}>
                {/* product */}
                {data.transaction_details.map((product, index) => {
                  let variants = [];
                  if (product?.variant_combinations?.variant_detail_one?.name) variants.push(product?.variant_combinations?.variant_detail_one?.name);
                  if (product?.variant_combinations?.variant_detail_two?.name) variants.push(product?.variant_combinations?.variant_detail_two?.name);

                  return (
                    <View key={index * 12} style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                      <View style={{ width: "37%" }}>
                        <View style={{ width: "90%" }}>
                          <Text style={{ lineHeight: "1.2px" }}>
                            {product.product.name} {variants.join(" - ")}
                          </Text>
                        </View>
                      </View>
                      <Text style={{ width: "19%" }}>{product.product.barcode}</Text>
                      <Text style={{ width: "19%" }}>{product.product.other_code}</Text>
                      <Text style={{ width: "10%" }}>{product.qty} pcs</Text>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default PackageLabel;
