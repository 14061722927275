// hooks
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { Calendar } from "primereact/calendar";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { MultiSelect } from "primereact/multiselect";

// api related
import Api from "../../api/Api";

// utils
import { useNavigate } from "react-router-dom";
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import priceFormatter from "../../utils/priceFormatter";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useDebounce } from "use-debounce";
import { pdf } from "@react-pdf/renderer";
import Invoice from "../../components/PDF/Invoice";

const TransactionList = ({ permissions }) => {
  // data limt will efect to page row and pagin
  let data_limit = 25;
  const navigate = useNavigate();

  // state
  const [globalFilter, setGlobalFilter] = useState("");
  const [queryValue] = useDebounce(globalFilter, 1000);
  const [page, setPage] = React.useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [pageDatTable, setPageDataTable] = useState({ first: 0, rows: 10, page: 1 });
  const [expandedRows, setExpandedRows] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [exportDates, setExportDates] = useState(null);
  const [filterDatesTemp, setFilterDatesTemp] = useState(null);
  const [filterDates, setFilterDates] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);
  const [statusFilterExport, setStatusFilterExport] = useState();
  const [dateTypeExport, setDateTypeExport] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // api calling
  const { data: transactionData, refetch } = useQuery({
    queryKey: ["transaction", page, queryValue, filterDates, statusFilter],
    queryFn: () => getTransactions(page),
    keepPreviousData: true,
  });

  // query
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/transaction", { data }), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("order Deleted!", { duration: 4000 });
      } catch (error) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const getTransactions = async () => {
    try {
      setIsLoading(true);
      let query = {
        limit: data_limit,
        page: page,
        sort_at: "DESC",
        sort_by: "created_at",
      };

      if (queryValue) {
        query.query = queryValue;
      }

      if (statusFilter?.length !== undefined) {
        query.status = statusFilter.join(",");
      }

      if (filterDates?.length) {
        let startDate = moment(filterDates[0]).format("YYYY-MM-DD");
        let endDate = filterDates[1] ? moment(filterDates[1]).format("YYYY-MM-DD") : startDate;

        query.start_date = startDate;
        query.end_date = endDate;
      }

      const res = await Api().get(`/transaction`, { params: query });
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfo(res.data.pages);
      setIsLoading(false);
      return res.data.data;
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      return error;
    }
  };

  const pageHandler = (data) => {
    setPageDataTable(data);
    setPage(data.page + 1);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      ids: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.ids.push(selectItems[i].id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      ids: [data.id],
    };

    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const handleDownload = async (blob1, blob2, name) => {
    try {
      // Read CSV blobs
      const csvData1 = await readBlobAsText(blob1);
      const csvData2 = await readBlobAsText(blob2);

      // Parse CSV data
      const parsedData1 = XLSX.utils.sheet_to_csv(XLSX.read(csvData1, { type: "binary" }).Sheets.Sheet1);
      const parsedData2 = XLSX.utils.sheet_to_csv(XLSX.read(csvData2, { type: "binary" }).Sheets.Sheet1);

      // Create new workbook
      const workbook = XLSX.utils.book_new();

      // Add worksheets to workbook
      XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet(parsedData1.split("\n").map((row) => row.split(","))), "Sheet1");
      XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet(parsedData2.split("\n").map((row) => row.split(","))), "Sheet2");

      // Convert workbook to blob
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const excelBlob = new Blob([wbout], { type: "application/octet-stream" });

      // Save file using FileSaver.js
      saveAs(excelBlob, name + ".xlsx");
    } catch (error) {
      console.error("Error converting CSV blobs to Excel:", error);
    }
  };

  const printLabel = async (transaction) => {
    try {
      let file_name = "";
      if (transaction.length > 1) {
        file_name = `WARDAH-invoice`;
      } else {
        file_name = `WARDAH-invoice-${transaction[0].resi || transaction[0].transaction_id}`;
      }

      let blobPdf = await pdf(Invoice({ values: transaction })).toBlob();

      var fileURL = URL.createObjectURL(blobPdf);
      var a = document.createElement("a");
      a.href = fileURL;
      a.target = "_blank";
      a.download = file_name;
      a.click();
      setSelectItems([]);
    } catch (error) {
      toast.error(error.message || "failed to print label");
    }
  };

  // Function to read blob as text
  const readBlobAsText = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsBinaryString(blob);
    });
  };

  const handleExport = async () => {
    try {
      if (!exportDates || !exportDates.length) {
        throw new Error("Please select date before export");
      }

      let startDate = moment(exportDates[0]).format("YYYY-MM-DD");
      let endDate = exportDates[1] ? moment(exportDates[1]).format("YYYY-MM-DD") : startDate;

      let params = {
        start_date: startDate,
        end_date: endDate,
      };

      if (statusFilterExport !== undefined) {
        params.status = statusFilterExport;
      }
      if (dateTypeExport !== undefined) {
        params.date_type = dateTypeExport;
      }

      const response = await Api().get("/transaction/export?&as_string=1", {
        params: params,
      });

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      const tab1 = new Blob([response.data.data.tab1], {
        type: "text/csv",
      });
      const tab2 = new Blob([response.data.data.tab2], {
        type: "text/csv",
      });
      handleDownload(tab1, tab2, `ReportTransaction-${startDate}_${endDate}`);
      setExportDates(null);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // child components
  const rowExpansionTemplate = (rowData) => {
    return (
      <>
        <div className="p-3">
          <p>
            <span className="font-bold">PRODUCTS LIST</span>
          </p>
          <DataTable emptyMessage="No data." value={rowData.transaction_details}>
            <Column
              body={(data) => (
                <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                  <Image preview imageClassName="object-contain w-full h-full" className="w-full h-full" src={data?.product?.images[0]?.url} alt="image" />
                </div>
              )}
              headerStyle={{ width: "150px", minWidth: "80px" }}
            ></Column>
            <Column field="product.name" header="Product Name"></Column>
            <Column
              field="variant_combinations"
              header="Variant"
              body={(d) => {
                let variantOne = d?.variant_combinations?.variant_detail_one?.name;
                let variantTwo = d?.variant_combinations?.variant_detail_two?.name;

                let displayTitle = [];

                if (variantOne) displayTitle.push(variantOne);
                if (variantTwo) displayTitle.push(variantTwo);

                return displayTitle.join(" - ") || "-";
              }}
            ></Column>
            <Column field="qty" header="Qty"></Column>
            <Column field="total_price" header="Price" body={(d) => priceFormatter(d.total_price)}></Column>
            <Column headerStyle={{ width: "4rem" }}></Column>
          </DataTable>

          <p className="mt-3">
            <span className="font-bold">DOCUMENTS</span>
          </p>

          <div className="flex  gap-4" style={{ gap: "8px" }}>
            {!rowData?.ioss_number && !rowData?.extra_docs_1?.url && !rowData?.extra_docs_1?.url ? <p>No Doucment Found.</p> : null}

            {rowData?.ioss_number ? (
              <p>
                IOSS Number : <b>{rowData?.ioss_number}</b>
              </p>
            ) : null}
            {rowData?.extra_docs_1?.url ? (
              <div className="">
                <p>{rowData?.receiver_address?.country_data?.code === "IN" ? "Aadhar" : "ARC"} Card :</p>
                <div>
                  <Image preview width="80px" height="80px" src={rowData?.extra_docs_1?.url} alt="extra-document-1" />.
                </div>
              </div>
            ) : null}
            {rowData?.extra_docs_2?.url ? (
              <div className="">
                <p>PAN Card :</p>
                <div>
                  <Image preview width="80px" height="80px" src={rowData?.extra_docs_2?.url} alt="extra-document-1" />.
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.orders.length > 0;
  };

  // const leftToolbarTemplate = () => {
  //   return (
  //     <React.Fragment>
  //       <div className="my-2">
  //         {/* {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => navigate("/dashboard/product/create")} />} */}
  //         {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => navigate(`/dashboard/transaction-list/update/${rowData.transaction_id}`)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="">
        <section className="flex justify-content-between align-items-center">
          {/* title */}
          <div className="">
            <h5 className="m-0">Manage Order</h5>
          </div>

          <div className="flex" style={{ gap: "14px" }}>
            <div className="mt-auto">
              <div className="flex flex-column">
                <label htmlFor="">Filter Order Status</label>
                <Dropdown
                  optionLabel="label"
                  showClear
                  optionValue="value"
                  value={statusFilterExport}
                  options={TransactionStatus.map((d, index) => ({ label: d, value: index })).filter((t) => t.value !== 0)}
                  className="w-full  mt-1"
                  onChange={(e) => {
                    setStatusFilterExport(e.target.value);
                  }}
                  placeholder="Choose option"
                />
              </div>
            </div>
            <div className="mt-auto">
              <div className="flex flex-column">
                <label htmlFor="">Date Type</label>
                <Dropdown
                  optionLabel="label"
                  optionValue="value"
                  value={dateTypeExport}
                  showClear
                  options={[
                    { label: "Created Date", value: 0 },
                    { label: "Settlement Date", value: 1 },
                  ]}
                  className="w-full  mt-1"
                  onChange={(e) => {
                    setDateTypeExport(e.target.value);
                  }}
                  placeholder="Choose option"
                />
              </div>
            </div>
            <div className="flex align-items-end">
              <div className="flex flex-column">
                <label htmlFor="">Order Date</label>
                <Calendar className="mt-1" showButtonBar value={exportDates} onChange={(e) => setExportDates(e.value)} selectionMode="range" placeholder="Select date range" />
              </div>
              <div className="ml-2">
                <Button disabled={!exportDates?.length} onClick={handleExport}>
                  Export
                </Button>
              </div>
            </div>
          </div>
        </section>

        <div className="flex justify-content-between align-items-end pt-4 mt-4" style={{ borderTop: "1px solid black", gap: "14px" }}>
          <div className="flex" style={{ gap: "10px" }}>
            <Button label={`Print Invoice (${selectItems.length})`} icon="pi pi-trash" onClick={() => printLabel(selectItems)} disabled={!selectItems.length} />
            {permissions.delete && <Button label={`Delete (${selectItems.length})`} icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}{" "}
          </div>

          <div className="flex" style={{ gap: "14px" }}>
            <div className="flex align-items-end">
              <div className="flex flex-column">
                <label htmlFor="">Filter By Date</label>
                <Calendar showButtonBar className="mt-1" value={filterDatesTemp} onChange={(e) => setFilterDatesTemp(e.value)} selectionMode="range" placeholder="Select date range" />
              </div>
              <div className="ml-2">
                <Button disabled={!filterDatesTemp?.length} onClick={() => setFilterDates(filterDatesTemp)}>
                  Find
                </Button>
              </div>
            </div>
            <div className="mt-auto">
              <div className="flex flex-column">
                <label htmlFor="">Filter Order Status</label>
                <MultiSelect
                  optionLabel="label"
                  optionValue="value"
                  value={statusFilter}
                  options={TransactionStatus.map((d, index) => ({ label: d, value: index })).filter((t) => t.value !== 0)}
                  className="w-full  mt-1"
                  style={{ maxWidth: "250px" }}
                  showClear
                  onChange={(e) => {
                    setStatusFilter(e.target.value);
                  }}
                  placeholder="Choose option"
                />
              </div>
            </div>
            {/* export */}
            <div className="flex flex-column md:flex-row mt-4 md:mt-0" style={{ gap: "14px" }}>
              <span className="block mt-auto p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  // set page to 1 if gobal filter triggered
  useEffect(() => {
    if (globalFilter?.length) {
      setPage(1);
    }
    if (filterDates?.length) {
      setPage(1);
    }
    if (statusFilter) {
      setPage(1);
    }
  }, [globalFilter, statusFilter, filterDates]);

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            {/* <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar> */}
            <DataTable
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              loading={isLoading}
              value={transactionData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="id"
              paginator
              rows={data_limit}
              lazy
              onPage={pageHandler}
              first={pageDatTable.first}
              totalRecords={pageInfo.total_data}
              rowExpansionTemplate={rowExpansionTemplate}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              emptyMessage="No Data Found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column expander={allowExpansion} style={{ width: "5rem" }} />
              <Column field="transaction_id" header="Invoice Number" headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column field="resi" header="AWB" body={(d) => (d.resi ? d.resi : "-")} headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column field="user.email" header="User email" body={(data) => data?.user?.email || data.guest_email} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
              {/* <Column field="user.email" header="Reciever" body={(data) => data?.shipping_address?.user_name || data?.guest_name} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column> */}
              <Column field="created_at" header="Order Date" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ minWidth: "4rem" }}></Column>
              <Column field="shipping_price" header="Shipping (Price)" body={(d) => priceFormatter(d.shipping_price)} headerStyle={{ minWidth: "5rem" }}></Column>
              <Column field="total_payment" header="Total Payment" body={(d) => priceFormatter(d.total_payment)} headerStyle={{ minWidth: "5rem" }}></Column>
              <Column field="status" className="capitalize" header="Status" body={(d) => TransactionStatus[d.status]} headerStyle={{ minWidth: "5rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="ACTIONS" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "8rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} className="modal-container" header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Product?</span>}
        </div>
      </Dialog>

      {/* <DevTool control={updateForm.control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(TransactionList, comparisonFn);
