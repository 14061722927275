// hooks
import React, { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useMutation } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// config
import { InputTextarea } from "primereact/inputtextarea";

// api related
import { useGetPage } from "../../hook/page.hook";
import RolesApi from "../../api/RolesApi";

const CreateRole = () => {
  const navigate = useNavigate();

  // hooks
  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const { fields, append } = useFieldArray({
    control,
    name: "permissions",
  });

  // api calling
  const { data: pagesData, isLoading, isError } = useGetPage();

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await RolesApi.Add(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        navigate("/dashboard/role");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    let permissions = [];

    for (let i = 0; i < data.permissions.length; i++) {
      let current_permission = data.permissions[i].actions;

      permissions.push({
        create: current_permission.create ? 1 : 0,
        delete: current_permission.delete ? 1 : 0,
        update: current_permission.update ? 1 : 0,
        view: current_permission.view ? 1 : 0,
        name: data.permissions[i].name,
      });
    }

    data.permissions = permissions;
    crateMutate({ ...data });
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Role
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbar = () => {
    return (
      <React.Fragment>
        <Link to="/dashboard/role">
          <Button label="Back" className="p-button-outlined p-button-secondary ml-4" />
        </Link>
      </React.Fragment>
    );
  };

  const onPermissionChange = (index, key, value) => {
    if (value && key !== "view") {
      fields[index.rowIndex].actions.view = true;
    }
    fields[index.rowIndex].actions[key] = value;
    reset({ ...getValues(), permissions: [...fields] });
  };

  // lifecycle
  useEffect(() => {
    if (!isLoading) {
      let data_structure = [];
      for (let i = 0; i < pagesData.length; i++) {
        data_structure.push({
          actions: {
            create: false,
            update: false,
            delete: false,
            view: false,
          },
          name: pagesData[i].name,
        });
      }

      append(data_structure);
    }
  }, [isLoading, pagesData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isError]);

  return (
    <>
      <form style={{ borderRadius: 0 }} onSubmit={handleSubmit(onSubmit)} className="card col-12">
        <Toolbar className="mb-4" left={leftToolbar} right={rightToolbar} />
        <div>
          <div>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-12 mt-4">
                <label htmlFor="user">Role Title :</label>
                <InputText placeholder="Input Role Title" {...register("name", { required: true })} id="name" type="text" className={errors.name && "p-invalid"} />
                {errors.name && (
                  <small id="name-help" className="p-error block">
                    Name is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="user">Role Description :</label>
                <InputTextarea {...register("description", { required: true })} className={errors.description && "p-invalid"} placeholder="input description" autoResize rows="10" />
                {errors.description && (
                  <small id="name-help" className="p-error block">
                    Name is required
                  </small>
                )}
              </div>
              <div className="col-12 mt-5">
                <h5 htmlFor="user">Access Permissions:</h5>
                <DataTable value={fields} responsiveLayout="scroll">
                  <Column headerStyle={{ width: "30%", minWidth: "20rem" }} body={(data) => <p>{data.name}</p>} header="Page Name"></Column>
                  <Column
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    body={(data, index) => (
                      <div className="field-checkbox mr-2">
                        <Controller
                          control={control}
                          name={`permissions.${index.rowIndex}.actions.create`}
                          render={({ field }) => <Checkbox inputId={`permissions.${index.rowIndex}.actions.create`} onChange={(e) => onPermissionChange(index, "create", e.checked)} name={`permissions.${index.rowIndex}.actions.create`} checked={field.value} />}
                        />
                      </div>
                    )}
                    header="Create"
                  ></Column>
                  <Column
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    body={(data, index) => (
                      <div className="field-checkbox mr-2">
                        <Controller
                          control={control}
                          defaultValue={false}
                          name={`permissions.${index.rowIndex}.actions.update`}
                          render={({ field }) => <Checkbox inputId={`permissions.${index.rowIndex}.actions.update`} onChange={(e) => onPermissionChange(index, "update", e.checked)} name={`permissions.${index.rowIndex}.actions.update`} checked={field.value} />}
                        />
                      </div>
                    )}
                    header="Update"
                  ></Column>
                  <Column
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    body={(data, index) => (
                      <div className="field-checkbox mr-2">
                        <Controller
                          control={control}
                          defaultValue={false}
                          name={`permissions.${index.rowIndex}.actions.delete`}
                          render={({ field }) => <Checkbox inputId={`permissions.${index.rowIndex}.actions.delete`} onChange={(e) => onPermissionChange(index, "delete", e.checked)} name={`permissions.${index.rowIndex}.actions.delete`} checked={field.value} />}
                        />
                      </div>
                    )}
                    header="Delete"
                  ></Column>
                  <Column
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    body={(data, index) => (
                      <div className="field-checkbox mr-2">
                        <Controller
                          control={control}
                          defaultValue={false}
                          name={`permissions.${index.rowIndex}.actions.view`}
                          render={({ field }) => (
                            <Checkbox
                              disabled={fields[index.rowIndex].actions.create || fields[index.rowIndex].actions.update || fields[index.rowIndex].actions.delete}
                              inputId={`permissions.${index.rowIndex}.actions.view`}
                              onChange={(e) => onPermissionChange(index, "view", e.checked)}
                              name={`permissions.${index.rowIndex}.actions.view`}
                              checked={field.value}
                            />
                          )}
                        />
                      </div>
                    )}
                    header="View"
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>

          <div className="flex justify-content-center mt-4">
            <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
            <Link to="/dashboard/role">
              <Button type="button" label="Back" className=" p-button-secondary" />
            </Link>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(CreateRole, comparisonFn);
