// hooks
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";

// utils
import classNames from "classnames";
import DiscountUserTypeOptions from "../../assets/data/discount_user_type.json";
import DiscountTypeOptions from "../../assets/data/discount_type.json";

// api related
import Api from "../../api/Api";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import moment from "moment/moment";
import { Chips } from "primereact/chips";

const DiscountUpdate = () => {
  const navigate = useNavigate();
  const [isUsePercentage, setIsUsePercentage] = useState(false);
  const { id } = useParams();

  // hooks
  const { control, handleSubmit, formState, watch, reset, getValues } = useForm();
  const discount_type_watch = watch("type");
  const discount_type_limit_watch = watch("limit_type");

  // query
  const { data: productOption } = useQuery("product", () => getProduct());
  const { data: catData } = useQuery("product-category", () => getProductCategory(), { initialData: [] });
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/discount/edit", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        navigate("/dashboard/discount");
        toast.success("Discount updated!");
      } catch (error) {
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const getProduct = async () => {
    try {
      const res = await Api().get("/product");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getProductCategory = async () => {
    try {
      const res = await Api().get("category");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const onSubmit = async (data) => {
    if (isUsePercentage) {
      data.price = null;
    } else {
      data.percentage = null;
    }

    let total_price = +data.price + +data.shipping_price;

    if (total_price > data.requirement_price) {
      toast.error("Discount price cant be greater than requirement price");
      return;
    }

    data.id = id;

    data.start_date = formatDate(data.periode[0]);
    data.end_date = data.periode[1] ? formatDate(data.periode[1]) : formatDate(data.periode[0]);

    if (data.type === 5 || data.type === 6) {
      // id default courier
      data.requirement_courier = 1;
    } else {
      data.requirement_courier = data?.requirement_courier?.join(",") || null;
    }
    data.requirement_products = data?.requirement_products?.join(",") || null;
    data.requirement_categories = data?.requirement_categories?.join(",") || null;

    delete data.periode;

    data.auto_apply = data.auto_apply ? 1 : 0;
    data.active_status = data.active_status ? 1 : 0;
    data.user_first_transaction = data.user_first_transaction ? 1 : 0;

    if (data.eligible_for_user?.length) {
      const user_ids = await getUserIdsByEmails(data.eligible_for_user);
      data.eligible_for_user = user_ids.map((user) => user.id).join(",") || null;
    } else {
      delete data.eligible_for_user;
    }

    updateMutate(data);
  };

  const getUserIdsByEmails = async (payload, type = "email") => {
    try {
      let endpoint = "/user?page=1&limit=100&";

      if (type === "id") {
        endpoint = endpoint + "id=" + payload.join(",");
      } else {
        endpoint = endpoint + "email=" + payload.join(",");
      }

      const response = await Api().get(endpoint);

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      let result = [];
      if (response.data?.data?.length) {
        result = response.data.data;
      }

      return result;
    } catch (error) {
      toast.error(error.message);
    }
  };

  // to clear if selected type changes
  const clearIfTypeChange = () => {
    const currentVal = getValues();
    reset({ ...currentVal, requirement_categories: [], requirement_products: [], requirement_courier: [] });
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Discount
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const productSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img style={{ objectFit: "contain" }} width={50} height={50} alt={option.name} src={option?.images[0]?.url || ""} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const res = await Api().get("/discount/detail/" + id);

        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }

        // mapping
        let result = res.data.data;

        let start_date = new Date(result?.start_date);
        start_date.setHours(start_date.getHours() - 7);

        let end_date = new Date(result?.end_date);
        end_date.setHours(end_date.getHours() - 7);

        let periode = [start_date, end_date];

        if (+result.percentage > 0) {
          setIsUsePercentage(true);
        }

        // get users
        let users_email = [];

        if (result?.eligible_for_user && result?.eligible_for_user?.split(",")?.length) {
          const users = await getUserIdsByEmails(result?.eligible_for_user?.split(","), "id");
          users_email = users.map((user) => user.email);
        }

        if (result.requirement_products?.length) {
          result.requirement_products = result.requirement_products.split(",").map((d) => +d);
        }
        if (result.requirement_categories?.length) {
          result.requirement_categories = result.requirement_categories.split(",").map((d) => +d);
        }

        setTimeout(() => {
          reset({ ...result, periode, percentage: +result.percentage, active_status: Boolean(result.active_status), auto_apply: Boolean(result.auto_apply), user_first_transaction: Boolean(result.user_first_transaction), eligible_for_user: users_email });
        }, 500);

        return result;
      } catch (error) {
        toast.error(error.message);
        return error;
      }
    };

    getProductDetails();
  }, [id, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Promo Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="title"
              render={({ field }) => (
                <InputText
                  placeholder="Input discount name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Promo Code : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="code"
              render={({ field }) => (
                <InputText
                  placeholder="Input discount code"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.code,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.code && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.description && (
              <small id="description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="type">Promo Type : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Dropdown
                  inputRef={field.ref}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  value={field.value}
                  options={DiscountTypeOptions}
                  onChange={(e) => {
                    field.onChange(e);
                    clearIfTypeChange();
                  }}
                  optionLabel="label"
                  optionValue="id"
                  placeholder="Select type"
                  display="chip"
                />
              )}
            />
            {formState.errors?.type && (
              <small id="type" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        {discount_type_watch === 4 ? (
          <div className="field col-12">
            <label htmlFor="requirement_categories">Requirement Category : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="requirement_categories"
                rules={{ required: false }}
                render={({ field }) => (
                  <MultiSelect
                    className={classNames({ "p-invalid": formState.errors?.requirement_categories }, "w-full")}
                    emptyFilterMessage="no data found"
                    disabled={!catData?.length}
                    value={field.value}
                    options={catData}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select requirement category"
                    display="chip"
                  />
                )}
              />
              {formState.errors?.requirement_categories && (
                <small id="requirement_categories" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        ) : null}

        {(discount_type_watch === 3 || discount_type_watch === 6) && (
          <div className="field col-12">
            <label htmlFor="requirement_products">Requirement Products : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="requirement_products"
                render={({ field }) => (
                  <MultiSelect
                    className="w-full"
                    emptyFilterMessage="no data found"
                    itemTemplate={productSelectTemplate}
                    disabled={!productOption?.length}
                    value={field.value}
                    options={productOption}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select requirement products"
                    display="chip"
                  />
                )}
              />
              {formState.errors?.requirement_products && (
                <small id="requirement_products" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        )}

        <div className="field col-12">
          <label htmlFor="limit_type">Limit Type : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="limit_type"
              render={({ field }) => <Dropdown className="w-full" emptyFilterMessage="no data found" value={field.value} options={DiscountUserTypeOptions} onChange={(e) => field.onChange(e)} optionLabel="label" optionValue="id" placeholder="Select limit type" display="chip" />}
            />
            {formState.errors?.limit_type && (
              <small id="limit_type" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="qty">Usage Limit : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="qty"
            render={({ field }) => (
              <InputNumber disabled={discount_type_limit_watch === 3} className={classNames({ "p-invalid": formState.errors?.qty }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />
            )}
          />
          {formState.errors?.qty && (
            <small id="qty" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12">
          <label htmlFor="eligible_for_user">E-Mail Validation List (*Enter to select) : </label>
          <Controller
            control={control}
            name="eligible_for_user"
            render={({ field }) => <Chips className={classNames({ "p-invalid": formState.errors?.eligible_for_user }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onChange={(e) => field.onChange(e)} placeholder="Enter email list" />}
          />
          {formState.errors?.eligible_for_user && (
            <small id="eligible_for_user" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>

        <div className="field col-12 ">
          <label htmlFor="user_first_transaction">First Time Transaction : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="user_first_transaction" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>

        <div className="field col-12">
          <label htmlFor="price">Use Percentage : </label> <br />
          <InputSwitch checked={isUsePercentage} onChange={(e) => setIsUsePercentage(e.value)} />
        </div>
        {isUsePercentage ? (
          <div className="field col-12">
            <label htmlFor="percentage">Percentage (%) : </label>
            <div className="w-full">
              <Controller defaultValue={0} control={control} name="percentage" render={({ field }) => <InputNumber className="w-full" onBlur={field.onBlur} ref={field.ref} value={field.value} max={100} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
              {formState.errors?.percentage && (
                <small id="percentage" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        ) : (
          <div className="field col-12">
            <label htmlFor="price">Discount Price : </label>
            <Controller
              defaultValue={""}
              control={control}
              name="price"
              render={({ field }) => (
                <InputNumber className={classNames({ "p-invalid": formState.errors?.price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="currency" currency="USD" locale="en-US" placeholder="0" />
              )}
            />
            {formState.errors?.price && (
              <small id="price" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        )}

        <div className="field col-12">
          <label htmlFor="requirement_price">{discount_type_watch === 5 ? "Minimum Shipping Price" : "Minimum Purchase"} : </label>
          <Controller
            defaultValue={""}
            control={control}
            name="requirement_price"
            render={({ field }) => (
              <InputNumber className={classNames({ "p-invalid": formState.errors?.requirement_price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="currency" currency="USD" locale="en-US" placeholder="0" />
            )}
          />
          {formState.errors?.requirement_price && (
            <small id="requirement_price" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        {discount_type_watch === 6 ? (
          <div className="field col-12">
            <label htmlFor="shipping_price">Shipping Discount Price : </label>
            <Controller
              defaultValue={""}
              control={control}
              name="shipping_price"
              render={({ field }) => (
                <InputNumber className={classNames({ "p-invalid": formState.errors?.shipping_price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="currency" currency="USD" locale="en-US" placeholder="0" />
              )}
            />
            {formState.errors?.shipping_price && (
              <small id="shipping_price" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        ) : null}

        <div className="field col-12 lg:col-6">
          <label htmlFor="periode">Discount Period : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="periode"
              render={({ field }) => (
                <Calendar
                  showTime
                  hourFormat="24"
                  dateFormat="yy-mm-dd"
                  value={field.value}
                  onChange={(e) => field.onChange(e)}
                  minDate={new Date()}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.description,
                    },
                    "w-full"
                  )}
                  placeholder="Select periode range"
                  selectionMode="range"
                  readOnlyInput
                />
              )}
            />
            {formState.errors?.periode && (
              <small id="periode" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        <div className="field col-12 ">
          <label htmlFor="periode">Auto Apply : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="auto_apply" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>

        <div className="field col-12">
          <label htmlFor="active_status">Active Status : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>

        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={updateLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/discount">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(DiscountUpdate, comparisonFn);
